import {ReferenceInput, Edit, SimpleForm, Toolbar, SaveButton, AutocompleteInput} from "react-admin";

const ShopEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            transform={data => ({ owner: data.owner })}
            submitOnEnter={false}
        />
    </Toolbar>
);

const shopEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ShopEditToolbar />}>
                <ReferenceInput label={"Owner"} source={"owner"} reference={"sellers"}>
                    <AutocompleteInput optionText={"email"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export default shopEdit;
