import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';
import Cookies from 'js-cookie';

function AuthProvider(type, params) {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(
            window._env_.apiUrl + '/auth_admin',
            {
                method: "POST",
                body: JSON.stringify({email: username, password}),
                headers: new Headers({"Content-Type": "application/json"}),
                credentials: 'include'
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
            });
    }

    if (type === AUTH_LOGOUT) {
        Cookies.remove('JWT_HP', { domain: window._env_.cookieDomain });
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return Cookies.get('JWT_HP') ? Promise.resolve() : Promise.reject();
    }

    return Promise.resolve();
}

export default AuthProvider;
