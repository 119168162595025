import React from "react";
import {fetchUtils, Resource, Layout} from "react-admin";
import {HydraAdmin, ResourceGuesser, hydraDataProvider} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import TreeMenu from '@bb-tech/ra-treemenu'

import shopList from './shopping/shopList';
import shopEdit from './shopping/shopEdit';

import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import authProvider from './authProvider';

const entrypoint = window._env_.apiUrl;

const httpClient = (url, options = {}) => {
    let headers = new Headers({
        "Accept": "application/ld+json",
    });

    if (!options.headers) {
        if (url.pathname !== '/territory_banners') {
            headers.append("Content-Type", "application/json");
        }
        options.headers = headers;
    }

    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};

const apiDocumentationParser = async () => {
    return parseHydraDocumentation(entrypoint, {
        credentials: 'include',
        headers: new Headers({
            "Accept": "application/ld+json",
            "Content-Type": "application/json"
        })
    });
};

const dataProvider = hydraDataProvider(entrypoint, httpClient, apiDocumentationParser);

function App()  {
    return (
        <HydraAdmin
            authProvider={authProvider}
            dataProvider={dataProvider}
            entrypoint={entrypoint}
            layout={(props) => <Layout {...props} menu={TreeMenu}/>}
        >
            {/* administration */}
            {/*<Resource name={"administration"} options={{"label": "Administration", "isMenuParent": true}} />*/}
            {/*<ResourceGuesser name={"admins"} icon={PeopleIcon} options={{"label": "Administrateurs", "menuParent": "administration"}} />*/}

            {/* shops */}
            <Resource name={"shopping"} options={{"label": "Commerces", "isMenuParent": true}} />
            <ResourceGuesser name={"sellers"} icon={PeopleIcon} options={{"label": "Commerçants", "menuParent": "shopping"}} list={null} />
            <ResourceGuesser name={"shops"} icon={StorefrontIcon} options={{"label": "Commerces", "menuParent": "shopping"}} list={shopList} edit={shopEdit} />

            {/* customers */}
            {/*<Resource name={"customers"} options={{"label": "Clients", "isMenuParent": true}} />*/}
            {/*<ResourceGuesser name={"buyers"} icon={PeopleIcon} options={{"label": "Clients", "menuParent": "customers"}} />*/}

            {/* configuration */}
            {/*<Resource name={"configuration"} options={{"label": "Configuration", "isMenuParent": true}} />*/}
            <ResourceGuesser name={"townships"} icon={LocationCityIcon} options={{"label": "Townships", "menuParent": "configuration"}} list={null} />
        </HydraAdmin>
    )
}

export default App;