import {ReferenceField, TextField} from "react-admin";
import {ListGuesser, FieldGuesser} from "@api-platform/admin";

const shopList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <FieldGuesser source="siret" />
        <FieldGuesser source="enabled" />
        <ReferenceField label="Township" source="township" reference="townships">
            <TextField source="city" />
        </ReferenceField>
        <ReferenceField label="Owner" source="owner" reference="sellers">
            <TextField source="email" />
        </ReferenceField>
    </ListGuesser>
);

export default shopList;
